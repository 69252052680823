/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { getVerticalReadRole } from '@/config/mvMapping';
import * as userProfileApi from '@/features/settings/profile/api';

import { RegistrationPayload } from '../onboarding/types';
import { userProfileFormData } from '../settings/profile/types';

import * as api from './api';
import { formatData } from './dataFormatting';
import {
  generalConfigsFetchFailed,
  generalConfigsFetchRequest,
  generalConfigsFetchSuccess,
  headerDataFetchFailed,
  headerDataFetchRequest,
  headerDataFetchSuccess,
  masqueradingListsFetchFailure,
  masqueradingListsFetchRequest,
  masqueradingListsFetchSuccess,
  masqueradingRegistrationFetchFailure,
  masqueradingRegistrationFetchRequest,
  masqueradingRegistrationFetchSuccess,
  masqueradingRegistrationUpdateFailure,
  masqueradingRegistrationUpdateRequest,
  masqueradingRegistrationUpdateSuccess,
  parentOrgConfigFetchFailure,
  parentOrgConfigFetchRequest,
  parentOrgConfigFetchSuccess,
  performanceSummaryFailed,
  performanceSummaryFetch,
  performanceSummarySucceeded,
} from './slice';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/
export function* fetchHeaderData() {
  try {
    console.debug('fetching header data via API request');
    const state = yield* select();
    const { org_id: orgId, roles, activeVertical } = state.user;
    const role = getVerticalReadRole(roles, activeVertical);
    const payload = {
      authRequestPath: `${orgId}/${role}/${activeVertical}`,
    };

    const result = yield* call(api.fetchHeaderData, payload);

    if (result) {
      // TODO another time: move this to BE - low priority
      const sortedNetworks = result.data.networks.sort((a, b) => {
        if (a.live_sites < b.live_sites) {
          return 1;
        }
        if (a.live_sites > b.live_sites) {
          return -1;
        }

        return 0;
      });

      result.data.networks = sortedNetworks;
      yield* put(headerDataFetchSuccess({ ...result.data, activeVertical }));
    }
  } catch (error: any) {
    yield* put(headerDataFetchFailed(error.message));
  }
}

export function* fetchGeneralConfigs(action: any) {
  try {
    console.debug('fetching general configs...');
    const result = yield* call(api.fetchGeneralConfigs, action.payload);

    if (result) {
      yield* put(generalConfigsFetchSuccess(result.data));
    }
  } catch (error: any) {
    yield* put(generalConfigsFetchFailed(error.message));
  }
}

export function* fetchMasqueradingLists(action: any) {
  try {
    console.debug('fetching masquerading lists...');
    const result = yield* call(api.fetchMasqueradingLists, action.payload);

    yield* put(masqueradingListsFetchSuccess(result.data));
  } catch (error: any) {
    yield* put(masqueradingListsFetchFailure(error.message));
  }
}

export function* fetchParentOrgConfig(action: any) {
  try {
    console.debug('fetching masquerading lists...');
    const result = yield* call(api.fetchParentOrgConfig, action.payload);

    yield* put(parentOrgConfigFetchSuccess(result.data));
  } catch (error: any) {
    yield* put(parentOrgConfigFetchFailure(error.message));
  }
}

export function* fetchPerformanceSummary(action: any) {
  try {
    const state = yield* select();
    const { activeVertical } = state.user;
    const selectedTransactionType = state.dashboard.selectedTransactionType;

    const result = yield* call(api.fetchPerformanceSummaryData, action.payload);
    const refinedResult = formatData(result, action.payload.temporalUnit, activeVertical, selectedTransactionType);

    yield* put(performanceSummarySucceeded(refinedResult));
  } catch (error: any) {
    yield* put(performanceSummaryFailed(error.message));
  }
}

export function* handleMasqueradingRegistrationFetchAndUpdate(action: PayloadAction<RegistrationPayload>) {
  try {
    const result = yield* call(userProfileApi.fetchUserProfile, action.payload);

    const { userRegistration } = result;
    const { skipPaymentsStep, isDefaultWorkflow } = userRegistration;
    const { authRequestPath, data } = action.payload;
    const isLocationLaunched = data.isLocationLaunched;

    // Documentation: isOnboardingComplete is considered complete if at least:
    // 1) one site added
    // 2) one credit card or one verified bank payment method added
    // 3) one site is launched
    const isOnboardingComplete =
      (userRegistration.locationsStepCompleted && userRegistration.paymentsStepCompleted && isLocationLaunched) ||
      (skipPaymentsStep && !isDefaultWorkflow && isLocationLaunched);
    const updatedRegistrationData = {
      form_data: {
        ...userRegistration,
        locationsStepCompleted: !isDefaultWorkflow || data.isLocationAdded,
        paymentsStepCompleted: skipPaymentsStep || data.isBillingMethodAdded,
        isOnboarding: !isOnboardingComplete,
      },
      org_id: action.payload.data.orgId,
    };
    if (!isEqual(updatedRegistrationData.form_data, result.userRegistration)) {
      yield* put(masqueradingRegistrationUpdateRequest({ authRequestPath, data: updatedRegistrationData }));
    }
    yield* put(masqueradingRegistrationFetchSuccess(result.userRegistration));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(masqueradingRegistrationFetchFailure(error.message));
    }
  }
}

export function* handleMasqueradingRegistrationUpdate({
  payload: { authRequestPath, data },
}: PayloadAction<{ authRequestPath: string; data: userProfileFormData }>) {
  try {
    const result = yield* call(userProfileApi.updateUserProfile, { authRequestPath, data });
    if (result === 200) {
      yield* put(masqueradingRegistrationUpdateSuccess());
    } else {
      yield* put(masqueradingRegistrationUpdateFailure(`error: ${result}`));
    }
  } catch (error) {
    const axiosError = error as AxiosError;

    yield* put(masqueradingRegistrationUpdateFailure(axiosError.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/
export function* watchHeaderData() {
  yield* takeLatest(headerDataFetchRequest, fetchHeaderData);
}

export function* watchGeneralConfigs() {
  yield* takeLatest(generalConfigsFetchRequest, fetchGeneralConfigs);
}

export function* watchMasqueradingLists() {
  yield* takeLatest(masqueradingListsFetchRequest, fetchMasqueradingLists);
}

export function* watchMasqueradingRegistration() {
  yield* takeLatest(masqueradingRegistrationFetchRequest, handleMasqueradingRegistrationFetchAndUpdate);
}

export function* watchMasqueradingRegistrationUpdate() {
  yield* takeLatest(masqueradingRegistrationUpdateRequest, handleMasqueradingRegistrationUpdate);
}

export function* watchParentOrgConfig() {
  yield* takeLatest(parentOrgConfigFetchRequest, fetchParentOrgConfig);
}

export function* watchPerformanceSummary() {
  yield* takeLatest(performanceSummaryFetch, fetchPerformanceSummary);
}
