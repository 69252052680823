import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { all, call, put, race, select, take, takeLatest } from 'typed-redux-saga';

import * as api from '@/features/settings/profile/api';
import {
  selectUserRegistration,
  userRegistrationFetchFailure,
  userRegistrationFetchRequest,
  userRegistrationFetchSuccess,
  userRegistrationUpdateFailed,
  userRegistrationUpdateRequest,
  userRegistrationUpdateSucceeded,
} from '@/features/settings/profile/slice';

import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '../auth/userSlice';
import {
  headerDataFetchFailed,
  headerDataFetchRequest,
  headerDataFetchSuccess,
  selectLocationsFilter,
} from '../dashboard/slice';
import {
  locationsSettingsFailed,
  locationsSettingsFetchRequest,
  locationsSettingsSucceeded,
  selectLocationsSettings,
} from '../settings/locations/slice';
import {
  billingPaymentMethodsFailed,
  billingPaymentMethodsFetchRequest,
  billingPaymentMethodsSucceeded,
  selectBillingMethods,
} from '../settings/payment/billingPaymentMethods/slice';
import { userProfileFormData } from '../settings/profile/types';

import { RegistrationPayload } from './types';

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* handleUserRegistrationFetchAndUpdate(_action: PayloadAction<RegistrationPayload>) {
  try {
    let state = yield* select();
    const authRequestPath = selectAuthRequestPath(state);
    const activeVertical = selectActiveVertical(state);
    const roles = selectUserRoles(state);
    const orgId = selectUserOrg(state);

    yield* put(headerDataFetchRequest({ authRequestPath }));
    yield* put(locationsSettingsFetchRequest({ roles, orgId, activeVertical }));
    yield* put(billingPaymentMethodsFetchRequest({ authRequestPath, roles }));

    const [headerData, locationsResult, billingResult] = yield* all([
      race({
        success: take(headerDataFetchSuccess.type),
        failure: take(headerDataFetchFailed.type),
      }),
      race({
        success: take(locationsSettingsSucceeded.type),
        failure: take(locationsSettingsFailed.type),
      }),
      race({
        success: take(billingPaymentMethodsSucceeded.type),
        failure: take(billingPaymentMethodsFailed.type),
      }),
    ]);

    if (headerData.failure || locationsResult.failure || billingResult.failure) {
      throw new Error('Failed to initialize user registration page');
    }

    state = yield* select();

    const locations = selectLocationsFilter(state);
    const isLocationLaunched = locations.some((location) => location.site_network_join_date !== null);
    const locationsSettings = selectLocationsSettings(state);
    const userRegistration = { ...selectUserRegistration(state) };
    const { skipPaymentsStep, isDefaultWorkflow } = userRegistration;

    let regUpdated = false;
    const locationsStepCompleted = locationsSettings.length > 0;
    if (userRegistration.locationsStepCompleted !== locationsStepCompleted) {
      if (isDefaultWorkflow) {
        userRegistration.locationsStepCompleted = locationsStepCompleted;
        regUpdated = true;
      }
    }

    const paymentMethods = selectBillingMethods(state);
    const hasVerifiedBankAccount = paymentMethods.bank_accounts.some((bankAccount) => bankAccount.verified);
    const paymentsStepCompleted = paymentMethods.cards.length > 0 || hasVerifiedBankAccount;
    if (userRegistration.paymentsStepCompleted !== paymentsStepCompleted) {
      if (!skipPaymentsStep) {
        userRegistration.paymentsStepCompleted = paymentsStepCompleted;
        regUpdated = true;
      }
    }

    // Documentation: isOnboardingComplete is considered complete if at least:
    // 1) one site added
    // 2) one credit card or one verified bank payment method added
    // 3) one site is launched
    const isOnboardingComplete =
      isLocationLaunched &&
      (!isDefaultWorkflow || userRegistration.locationsStepCompleted) &&
      (skipPaymentsStep || userRegistration.paymentsStepCompleted);
    const isOnboarding = !isOnboardingComplete;
    if (userRegistration.isOnboarding !== isOnboarding) {
      userRegistration.isOnboarding = isOnboarding;
      regUpdated = true;
    }

    if (regUpdated) {
      const updatedRegistrationData = {
        form_data: {
          ...userRegistration,
        },
        org_id: orgId,
      };

      yield* put(userRegistrationUpdateRequest({ authRequestPath, data: updatedRegistrationData }));
      const result = yield* race({
        success: take(userRegistrationFetchSuccess.type),
        failure: take(userRegistrationFetchFailure.type),
      });

      if (result.failure) {
        throw new Error('Failed to initialize user registration page');
      }
    }

    yield* put(userRegistrationFetchSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield* put(userRegistrationFetchFailure(error.message));
    } else {
      yield* put(userRegistrationFetchFailure('Failed to initialize user registration page'));
    }
  }
}

export function* handleUserRegistrationUpdate({
  payload: { authRequestPath, data },
}: PayloadAction<{ authRequestPath: string; data: userProfileFormData }>) {
  try {
    const result = yield* call(api.updateUserProfile, { authRequestPath, data });
    if (result === 200) {
      yield* put(userRegistrationUpdateSucceeded(data.form_data));
    } else {
      yield* put(userRegistrationUpdateFailed(`error: ${result}`));
    }
  } catch (error) {
    const axiosError = error as AxiosError;

    yield* put(userRegistrationUpdateFailed(axiosError.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchUserRegistration() {
  yield* takeLatest(userRegistrationFetchRequest, handleUserRegistrationFetchAndUpdate);
}

export function* watchUserRegistrationUpdate() {
  yield* takeLatest(userRegistrationUpdateRequest, handleUserRegistrationUpdate);
}
