import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, FormControl, Heading, Input, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { Verticals } from '@/common/interfaces';
import { AccountRegistrationFormData } from '@/features/registration/types';
import { accountRegistrationSchema } from '@/features/registration/validators';

import {
  selectIsDefaultWorkflow,
  selectRegistrationData,
  updateIsDefaultWorkflow,
  updateRegistrationFormData,
} from '../slice';

const useQuery = () => {
  return new URLSearchParams(useLocation().search.toLowerCase());
};

export function AccountRegistration({ setCompletedStep }: { setCompletedStep: (step: number) => void }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const VERTICALS = Object.values(Verticals).filter(
    (value) => value !== Verticals.Unknown && value !== Verticals.CStore,
  );
  const isDefaultWorkflow = useAppSelector(selectIsDefaultWorkflow);
  const registrationFormData = useAppSelector(selectRegistrationData);
  const isDefaultWorkflowQuery = useQuery().get('isdefaultworkflow') === 'false' ? false : true;
  const [selectedVertical, setSelectedVertical] = useState(registrationFormData.formData.vertical);

  if (isDefaultWorkflow && isDefaultWorkflowQuery === false) dispatch(updateIsDefaultWorkflow(isDefaultWorkflowQuery));

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AccountRegistrationFormData>({
    resolver: zodResolver(accountRegistrationSchema),
    defaultValues: {
      formData: {
        ...registrationFormData.formData,
        numOfLocations: registrationFormData.formData.numOfLocations || '',
      },
    },
  });

  const onSubmit = (data: AccountRegistrationFormData) => {
    const updatedData = merge({ ...registrationFormData, ...data });
    dispatch(updateRegistrationFormData(updatedData));
    navigate('/registration/2');
    setCompletedStep(2);
    localStorage.setItem('completedStep', '2');
  };

  return (
    <Box mt={4}>
      <Heading as='h2' size='md' mb={4}>
        {t('registrationPage.titles.accountRegistration')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <Box mt='2'>
          <Text fontWeight='bold' mt='6'>
            {t('registrationPage.fields.verticalHelp')}
          </Text>
          <RadioGroup my={5} defaultValue={selectedVertical}>
            <VStack alignItems='flex-start'>
              {VERTICALS.map((td) => (
                <Radio
                  key={td}
                  value={td}
                  color='neutral.900'
                  {...register('formData.vertical')}
                  onClick={() => setSelectedVertical(td)}
                >
                  <Text fontSize='sm' onClick={() => setSelectedVertical(td)}>
                    {t(`registrationPage.verticals.${td}`)}
                  </Text>
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
          {errors.formData?.vertical && <Text color='red.500'>{errors.formData.vertical.message}</Text>}
        </Box>
        <Flex mt={2} gap={2}>
          <Box w='full'>
            <Text fontWeight='bold' mt={6} mb={2}>
              {t('registrationPage.fields.merchantLegalNameHelp')}
            </Text>
            <Text>{t('registrationPage.fields.merchantLegalName')}</Text>
            <CustomFormLabel htmlFor='merchantLegalName'>
              <Input
                id='merchantLegalName'
                mt={2}
                {...register('formData.merchantLegalName')}
                isInvalid={!!errors.formData?.merchantLegalName}
              />
              {errors.formData?.merchantLegalName && (
                <Text variant='error'>{errors.formData.merchantLegalName.message}</Text>
              )}
            </CustomFormLabel>
          </Box>
        </Flex>
        {selectedVertical === Verticals.Fuel && (
          <Flex mt={2} gap={2}>
            <Box w='full'>
              <Text fontWeight='bold' mt={6} mb={2}>
                {t('registrationPage.fields.numOfLocationsHelp')}
              </Text>
              <Text>{t('registrationPage.fields.numOfLocations')}</Text>
              <CustomFormLabel htmlFor='numOfLocations'>
                <Input
                  id='numOfLocations'
                  mt={2}
                  {...register('formData.numOfLocations')}
                  isInvalid={!!errors.formData?.numOfLocations}
                />
                {errors.formData?.numOfLocations && (
                  <Text variant='error'>{errors.formData.numOfLocations.message}</Text>
                )}
              </CustomFormLabel>
            </Box>
          </Flex>
        )}
        <Button mt={5} type='submit' variant='brandPrimary' size='md' px={8}>
          {t('registrationPage.actions.continue')}
        </Button>
      </FormControl>
    </Box>
  );
}
