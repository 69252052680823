import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import { useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Section } from '@/common/components/section';
import { useFetchBillingMethods } from '@/common/hooks';
import { Status } from '@/common/interfaces';

import { selectIsOnboarding } from '../../profile/slice';
import { BillingTableCell } from '../components/BillingTableCell';

import { selectBillingLoadingStatus, selectBillingMethods } from './slice';

export const BillingPaymentMethods = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loadingStatus = useAppSelector(selectBillingLoadingStatus);
  const billingPaymentMethods = useAppSelector(selectBillingMethods);
  const isOnboarding = useAppSelector(selectIsOnboarding);

  useFetchBillingMethods();

  const handleCreditCardClick = () => {
    navigate('/settings/payments/billing/add-credit-card');
  };

  const handleBankAccountClick = () => {
    navigate('/settings/payments/billing/add-bank-account');
  };

  const numOfPaymentMethods = billingPaymentMethods.cards.length + billingPaymentMethods.bank_accounts.length;

  return (
    <Box>
      {isOnboarding && (
        <Button
          leftIcon={<ChevronLeftIcon fontSize={23} />}
          mb={2}
          px={0}
          variant='accentTertiary'
          size='md'
          onClick={() => navigate('/onboarding')}
        >
          {t('onboardingPage.onboarding')}
        </Button>
      )}
      <Section name={t('payment.billingPaymentMethods.title')}>
        <Box>
          {loadingStatus === Status.Loading && (
            <Flex alignItems='center' justifyContent='center' mt={10}>
              <LoadingSpinner />
            </Flex>
          )}

          {loadingStatus === Status.Failed && (
            <Flex alignItems='center' justifyContent='center'>
              <DataLoadingErrorPanel />
            </Flex>
          )}
          {loadingStatus === Status.Idle && (
            <>
              <Box mt={3} data-testid='billing-title'>
                {numOfPaymentMethods > 0 ? (
                  <Trans
                    i18nKey={'payment.billingPaymentMethods.subTitle'}
                    components={{ eLink: <Link href='mailto:businesshelp@upside.com' color={'blue.400'} /> }}
                  >
                    {t('payment.billingPaymentMethods.subTitle')}
                  </Trans>
                ) : (
                  t('payment.billingPaymentMethods.noAccounts.subTitle')
                )}
                {isOnboarding && (
                  <Box padding={0} margin={0} maxW='100%' mt={4}>
                    <Trans
                      i18nKey={'onboardingPage.businessHelp'}
                      components={{
                        eLink: <Link href='mailto:businesshelp@upside.com' color={'blue.400'} />,
                      }}
                    >
                      {t('onboardingPage.businessHelp')}
                    </Trans>
                  </Box>
                )}
              </Box>

              {!!numOfPaymentMethods && (
                <Flex justifyContent={'end'} mt={4}>
                  <Button
                    onClick={onOpen}
                    variant='accentTertiary'
                    p={0}
                    fontSize='sm'
                    data-testid='add-billing-account'
                  >
                    {t('payment.billingPaymentMethods.addBillingMethodBtn')}
                  </Button>
                  <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>{t('payment.billingPaymentMethods.modal.title')}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>{t('payment.billingPaymentMethods.modal.subTitle')}</ModalBody>

                      <ModalFooter>
                        <Button
                          variant='brandPrimary'
                          onClick={handleCreditCardClick}
                          w='100%'
                          mr={'12px'}
                          data-testid='add-credit-card'
                        >
                          {t('payment.billingPaymentMethods.modal.creditCard')}
                        </Button>
                        <Button
                          variant='brandPrimary'
                          onClick={handleBankAccountClick}
                          w='100%'
                          data-testid='add-bank-account'
                        >
                          {t('payment.billingPaymentMethods.modal.bankAccount')}
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Flex>
              )}

              <TableContainer width={'100%'} overflowX={'hidden'} mt={2}>
                <ChakraTable style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}>
                  <Tbody>
                    <>
                      {!billingPaymentMethods.cards.length && !billingPaymentMethods.bank_accounts.length && (
                        <Tr>
                          <Td w={'30%'}>{t('payment.billingPaymentMethods.noAccounts.info')}</Td>
                          <Td textAlign={'right'} w={'70%'}>
                            <Button onClick={onOpen} size='md' variant='brandPrimary' data-testid='add-billing-account'>
                              {t('payment.billingPaymentMethods.noAccounts.addPayment')}
                            </Button>
                            <Portal>
                              <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalHeader>{t('payment.billingPaymentMethods.modal.title')}</ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody>{t('payment.billingPaymentMethods.modal.subTitle')}</ModalBody>

                                  <ModalFooter>
                                    <Button
                                      variant='brandPrimary'
                                      onClick={handleCreditCardClick}
                                      w='100%'
                                      mr={'12px'}
                                      data-testid='add-credit-card'
                                    >
                                      {t('payment.billingPaymentMethods.modal.creditCard')}
                                    </Button>
                                    <Button
                                      variant='brandPrimary'
                                      onClick={handleBankAccountClick}
                                      w='100%'
                                      data-testid='add-bank-account'
                                    >
                                      {t('payment.billingPaymentMethods.modal.bankAccount')}
                                    </Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal>
                            </Portal>
                          </Td>
                        </Tr>
                      )}

                      {!!billingPaymentMethods.cards.length && (
                        <>
                          {billingPaymentMethods.cards.map((card) => {
                            return (
                              <BillingTableCell
                                key={card.id}
                                method={card}
                                methodsLength={numOfPaymentMethods}
                                sectionName={'billing'}
                              />
                            );
                          })}
                        </>
                      )}
                      {!!billingPaymentMethods.bank_accounts.length && (
                        <>
                          {billingPaymentMethods.bank_accounts.map((bankAccount) => {
                            return (
                              <BillingTableCell
                                key={bankAccount.id}
                                method={bankAccount}
                                methodsLength={numOfPaymentMethods}
                                sectionName={'billing'}
                              />
                            );
                          })}
                        </>
                      )}
                    </>
                  </Tbody>
                </ChakraTable>
              </TableContainer>
            </>
          )}
        </Box>
      </Section>
    </Box>
  );
};
