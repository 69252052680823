import { useAuth0 } from '@auth0/auth0-react';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';

import { Routes } from '@/app/components/routes';
import { LoadingScreen } from '@/common/components/loadingScreen';
import { useInitializeUser } from '@/common/hooks';
import { Status } from '@/common/interfaces';
import { selectUserStatus } from '@/features/auth/userSlice';
import { selectUserProfileStatus } from '@/features/settings/profile/slice';

import { ENV_CONFIG } from '../config';

import { AuthInjector } from './components/authInterceptor';
import { useAppSelector } from './hooks';

const optimizelyClient = createInstance({
  sdkKey: ENV_CONFIG.OPTIMIZELY,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 5 * 60 * 1000, // 5 minutes
  },
  eventBatchSize: 100,
  eventFlushInterval: 5000, // 5 seconds
  logLevel: 'error', // comment out to debug in local dev, leave in `error` mode to keep console logs clean
});

export const App = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const userStatus = useAppSelector(selectUserStatus);
  const userProfileStatus = useAppSelector(selectUserProfileStatus);

  // useIsDemoDashboard(); // Not in use yet; awaiting Route53 setup and other work before enabling
  useInitializeUser();

  if (isAuthenticated && (isLoading || userStatus === Status.Loading || userProfileStatus === Status.Loading)) {
    return <LoadingScreen />;
  }

  const optimizelyUser = user ? user.sub : 'anonymous';

  return (
    <OptimizelyProvider optimizely={optimizelyClient} timeout={500} user={{ id: optimizelyUser as string }}>
      <AuthInjector />
      <Routes />
    </OptimizelyProvider>
  );
};
