import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import { Box, Button, Card, Checkbox, Flex, FormControl, Heading, Input, Link, Text, Tooltip } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { LoginDetailsFormData, RegistrationFormData } from '@/features/registration/types';
import { loginDetailsSchema } from '@/features/registration/validators';

import {
  selectHasClickedLink,
  selectRegistrationData,
  selectRegistrationSubmitted,
  submitRegistrationRequest,
  updateHasClickedLink,
  updateRegistrationFormData,
} from '../slice';

export function LoginDetails() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const registrationFormData = useAppSelector(selectRegistrationData);
  const hasClickedLink = useAppSelector(selectHasClickedLink);
  const submitted = useAppSelector(selectRegistrationSubmitted);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [linkClicked, setLinkClicked] = useState(hasClickedLink);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<LoginDetailsFormData>({
    resolver: zodResolver(loginDetailsSchema),
    defaultValues: {
      ...registrationFormData,
      hasClickedLink: linkClicked,
    },
  });

  const onSubmit = async (data: LoginDetailsFormData) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha('signup');

      const updatedData = merge({ ...registrationFormData, ...data }) as RegistrationFormData;
      updatedData.formData.recaptchaToken = recaptchaToken;

      dispatch(updateRegistrationFormData(updatedData));
      dispatch(submitRegistrationRequest(updatedData));
    } catch (error) {
      console.error('Recaptcha execution failed', error);
    }
  };

  const handleLinkClick = () => {
    setLinkClicked(true);
    setValue('hasClickedLink', true);
    dispatch(updateHasClickedLink(true));
  };

  const policyURL = `https://www.upside.com/data-and-security/${registrationFormData.formData.vertical}`;
  const newWindowParams = 'width=800,height=600,scrollbars=yes,resizable=yes';

  return (
    <Box mt={4}>
      <Heading as='h2' size='md' mb={4}>
        {t('registrationPage.titles.loginDetails')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <Text fontWeight='bold' mt='6'>
          {t('registrationPage.titles.loginDetailsHelp')}
        </Text>

        {submitted && (
          <Card bg='green.200'>
            <Flex gap={4} justifyContent='center' alignItems='center' height='100%'>
              <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
              <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'} verticalAlign={'self'}>
                {t('registrationPage.fields.successMessage')}
              </Text>
            </Flex>
          </Card>
        )}

        <CustomFormLabel htmlFor='email'>
          <Tooltip label={t('registrationPage.actions.onHover')} aria-label='Email tooltip' hidden={!submitted}>
            <Input
              id='email'
              mt={2}
              {...register('formData.email')}
              isInvalid={!!errors.formData?.email}
              autoComplete='email'
              isDisabled={submitted}
              onMouseEnter={() => console.log('Hovered over email input')}
              onMouseLeave={() => console.log('Mouse left email input')}
            />
          </Tooltip>
          {errors.formData?.email && <Text variant='error'>{errors.formData.email.message}</Text>}
        </CustomFormLabel>

        {registrationFormData.formData.isDefaultWorkflow && (
          <>
            <Checkbox
              mt={8}
              w='full'
              {...register('formData.acceptTerms')}
              isInvalid={!!errors.formData?.acceptTerms}
              isDisabled={submitted}
              style={{ fontSize: 'body.md' }}
            >
              {t('registrationPage.fields.acceptTerms1')}{' '}
              <Link
                href={policyURL}
                isExternal
                onClick={(e) => {
                  e.preventDefault();
                  window.open(policyURL, '_blank', newWindowParams);
                  handleLinkClick();
                }}
                color={'text.primary.default'}
              >
                {t('registrationPage.fields.acceptTerms2')}
              </Link>
            </Checkbox>
            {errors.formData?.acceptTerms && <Text variant='error'>{errors.formData.acceptTerms.message}</Text>}
            {errors.hasClickedLink && !linkClicked && <Text variant='error'>{errors.hasClickedLink.message}</Text>}
          </>
        )}

        {!registrationFormData.formData.isDefaultWorkflow && (
          <Box mt={8}>
            {t('registrationPage.fields.acceptTermsStreamlined1')}{' '}
            <Link
              href={policyURL}
              isExternal
              onClick={(e) => {
                e.preventDefault();
                window.open(policyURL, '_blank', newWindowParams);
                handleLinkClick();
              }}
            >
              {t('registrationPage.fields.acceptTermsStreamlined2')}
            </Link>
            {t('registrationPage.fields.acceptTermsStreamlined3')}
          </Box>
        )}

        <Tooltip label={t('registrationPage.actions.onHover')} aria-label='Email tooltip' hidden={!submitted}>
          <Button mt={5} type='submit' variant='brandPrimary' size='md' px={8} isDisabled={submitted}>
            {t('registrationPage.actions.submit')}
          </Button>
        </Tooltip>
      </FormControl>
    </Box>
  );
}
