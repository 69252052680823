import axios from 'axios';

import { ENV_CONFIG } from '../../config';

export const axiosInstance = axios.create({
  baseURL: ENV_CONFIG.API_URL,
  headers: {},
});

export const axiosInstanceUnauthenticated = axios.create({
  baseURL: ENV_CONFIG.API_URL,
  headers: {},
});

const headers: { [name: string]: string } = {};
const masquerading: {
  userUuid?: string | null;
  merchantUuid?: string | null;
  orgId?: string | null;
  isUpsider: boolean;
  isParentOrg: boolean;
} = { isUpsider: false, isParentOrg: false };

export const setRequestMasquerade = (
  isUpsider: boolean,
  isParentOrg: boolean,
  merchantUuid: string | null,
  userUuid: string | null,
  orgId: string | null,
) => {
  masquerading.isUpsider = isUpsider;
  masquerading.isParentOrg = isParentOrg;
  masquerading.userUuid = null;
  masquerading.merchantUuid = null;
  masquerading.orgId = orgId;

  if (isUpsider) {
    if (userUuid) {
      masquerading.userUuid = userUuid;
    } else if (merchantUuid) {
      masquerading.merchantUuid = merchantUuid;
    }
  }

  if (isParentOrg && merchantUuid) {
    masquerading.merchantUuid = merchantUuid;
  }
};

//This allows you to intercept the request before it is sent and alter headers or anything else that is passed to the axios config.
axiosInstance.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  for (const [key, value] of Object.entries(headers)) {
    config.headers[key] = value;
  }

  if (masquerading.isUpsider) {
    if (masquerading.userUuid) {
      delete config.data.merchant_uuid;
      config.data.user_uuid = masquerading.userUuid;
    } else if (masquerading.merchantUuid) {
      if (config.data.user_uuid) {
        delete config.data.user_uuid;
      }
      config.data.merchant_uuid = masquerading.merchantUuid;
    }
    config.data.org_id = masquerading.orgId;
  }

  if (masquerading.isParentOrg) {
    if (masquerading.merchantUuid) {
      delete config.data.user_uuid;
      config.data.merchant_uuid = masquerading.merchantUuid;
    }
    config.data.org_id = masquerading.orgId;
  }

  return config;
});

axiosInstanceUnauthenticated.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  for (const [key, value] of Object.entries(headers)) {
    config.headers[key] = value;
  }
  return config;
});
