import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';

import { RegistrationFormData } from './types';

export interface RegistrationState {
  submitted: boolean;
  data: RegistrationFormData;
  hasClickedLink: boolean;
  status: Status;
  error?: string;
}

export const initialState: RegistrationState = {
  submitted: false,
  data: {
    formData: {
      firstName: '',
      middleInitial: '',
      lastName: '',
      email: '',
      phone: '',
      vertical: 'fuel',
      merchantLegalName: '',
      acceptTerms: false,
      isDefaultWorkflow: true,
      recaptchaToken: '',
      numOfLocations: undefined,
    },
  },
  hasClickedLink: false,
  status: Status.Idle,
  error: '',
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    submitRegistrationRequest: (state, _action: PayloadAction<RegistrationFormData>) => {
      state.submitted = false;
      state.error = undefined;
      state.status = Status.Loading;
    },
    submitRegistrationSuccess: (state) => {
      state.submitted = true;
      state.error = undefined;
      state.status = Status.Idle;
    },
    submitRegistrationFailure: (state, action: PayloadAction<string>) => {
      state.submitted = false;
      state.error = action.payload;
      state.status = Status.Failed;
    },
    updateRegistrationFormData: (state, action: PayloadAction<RegistrationFormData>) => {
      state.data = action.payload;
    },
    updateIsDefaultWorkflow: (state, action: PayloadAction<boolean>) => {
      state.data.formData.isDefaultWorkflow = action.payload;
    },
    updateHasClickedLink: (state, action: PayloadAction<boolean>) => {
      state.hasClickedLink = action.payload;
    },
  },
});

export const selectRegistrationData = (state: RootState) => state.registration.data;
export const selectRegistrationSubmitted = (state: RootState) => state.registration.submitted;
export const selectIsDefaultWorkflow = (state: RootState) => state.registration.data.formData.isDefaultWorkflow;
export const selectHasClickedLink = (state: RootState) => state.registration.hasClickedLink;

export const {
  submitRegistrationRequest,
  submitRegistrationSuccess,
  submitRegistrationFailure,
  updateRegistrationFormData,
  updateIsDefaultWorkflow,
  updateHasClickedLink,
} = registrationSlice.actions;

export default registrationSlice.reducer;
