import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCool } from 'react-icons/bi';
import { FiInfo } from 'react-icons/fi';
import { FixedSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RadioGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';

import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { INITIAL_TRANSACTION_TYPE, RequestPayload, Status } from '@/common/interfaces';
import { whichVerticalFromRoles } from '@/config/mvMapping';
import {
  selectActiveMerchantName,
  selectIsParentOrg,
  selectIsUpsider,
  selectUserOrg,
  setUpsideMasquerade,
} from '@/features/auth/userSlice';
import { NavItem } from '@/features/dashboard/components/nav/NavItem';
import {
  selectMasqueradeLists,
  setMasqueradingReg,
  setProgramBasicsTab,
  setSelectedLocations,
  setSelectedNetworks,
  setSelectedTransactionType,
} from '@/features/dashboard/slice';
import { setHasDataOnFirstLoad } from '@/features/reports/financialReports/invoices/slice';
import { userProfileFetchRequest } from '@/features/settings/profile/slice';

import { MerchantRadio } from './MerchantRadio';
import { TMerchantUuid, TUserUuid } from './types';

export const MerchantSelector = () => {
  const listRef = useRef<FixedSizeList>(null);
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const orgId = useAppSelector(selectUserOrg);
  const isUpsider = useAppSelector(selectIsUpsider);
  const isParentOrg = useAppSelector(selectIsParentOrg);
  const activeMerchantName = useAppSelector(selectActiveMerchantName);
  const masqueradeLists = useAppSelector(selectMasqueradeLists);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [masqueradeSelection, setMasqueradeSelection] = useState<any>();
  const [merchantList, setMerchantList] = useState<TMerchantUuid[]>([]);
  const [userList, setUserList] = useState<TUserUuid[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const { user } = useAuth0();

  const listOfUserUuids = masqueradeLists.userUuids;
  const listOfMerchantUuids = masqueradeLists.merchantUuids;
  const isLoading = masqueradeLists.status === Status.Loading;

  const filterMerchantsForParentOrg = useCallback(
    (listOfMerchantUuids: TMerchantUuid[]) => {
      const filteredList = listOfMerchantUuids.filter((merchant: TMerchantUuid) =>
        merchant.parentOrg?.orgIds?.includes(orgId),
      );
      return _.sortBy(filteredList, 'name');
    },
    [orgId],
  );

  // If ParentOrg, set the default "retailer" to show for the merchant user
  useEffect(() => {
    if (isParentOrg && !isLoading) {
      if (!activeMerchantName) {
        const filteredListOfMerchantUuids = filterMerchantsForParentOrg(listOfMerchantUuids);
        console.debug('Setting default selection');
        console.debug(filteredListOfMerchantUuids[0]);
        dispatch(setUpsideMasquerade(filteredListOfMerchantUuids[0]));
      }
    }
  }, [activeMerchantName, dispatch, filterMerchantsForParentOrg, isLoading, isParentOrg, listOfMerchantUuids]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredMerchants = useMemo(() => {
    if (merchantList) {
      if (!merchantList.length) {
        return {
          list: [],
          count: 0,
        };
      }

      const filtered = merchantList.filter((merchant: TMerchantUuid) => {
        return `${merchant.name} - ${merchant.vertical}`.toLowerCase().includes(searchTerm.toLowerCase());
      });

      return {
        list: filtered,
        count: filtered.length,
      };
    } else {
      return {
        list: [],
        count: 0,
      };
    }
  }, [merchantList, searchTerm]);

  const filteredUsersEmails = useMemo(() => {
    if (!userList.length) {
      return {
        list: [],
        count: 0,
      };
    }

    const filtered = userList.filter((user: TUserUuid) => {
      return `${user.name} - ${user.email} - ${user.vertical}`.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return {
      list: filtered,
      count: filtered.length,
    };
  }, [userList, searchTerm]);

  const handleMasqueradeSelection = () => {
    // reset previous user selections
    dispatch(setSelectedTransactionType(INITIAL_TRANSACTION_TYPE));
    dispatch(setSelectedLocations([]));
    dispatch(setSelectedNetworks([]));
    dispatch(setProgramBasicsTab(0));
    dispatch(setHasDataOnFirstLoad(null));
    dispatch(
      setMasqueradingReg({
        isOnboarding: false,
        isDefaultWorkflow: true,
        locationsStepCompleted: false,
        paymentsStepCompleted: false,
        skipPaymentsStep: false,
        status: Status.Idle,
        error: '',
      }),
    );

    console.debug(masqueradeSelection);

    const chosenMasquerade = _.find(tabIndex === 0 ? listOfMerchantUuids : listOfUserUuids, {
      uuid: masqueradeSelection,
    });
    dispatch(setUpsideMasquerade(chosenMasquerade));

    if (user) {
      const roles = user['dashboard/roles'];
      const role =
        roles.find((element: string) => element.includes('ReadOnly') || element.includes('UpsideManager')) || '';
      const vertical = whichVerticalFromRoles(roles);
      const generalPayload: RequestPayload = { authRequestPath: `${user.org_id}/${role}/${vertical}` };
      dispatch(userProfileFetchRequest(generalPayload));
    }
    onClose();
  };

  const handleMasqueradeSelect = useCallback((merchant: string) => {
    setMasqueradeSelection(merchant);
  }, []);

  useEffect(() => {
    if (isParentOrg) {
      const filteredListOfMerchantUuids = filterMerchantsForParentOrg(listOfMerchantUuids);
      setMerchantList(filteredListOfMerchantUuids);
    } else if (isUpsider) {
      setMerchantList(listOfMerchantUuids);
      setUserList(listOfUserUuids);
    }

    if (!activeMerchantName && !isParentOrg) {
      onOpen();
    }
  }, [
    activeMerchantName,
    filterMerchantsForParentOrg,
    isParentOrg,
    isUpsider,
    listOfMerchantUuids,
    listOfUserUuids,
    onOpen,
  ]);

  return (
    <>
      <NavItem
        key={'Masquerade'}
        path={'#'}
        onClick={onOpen}
        icon={isUpsider ? BiCool : undefined}
        data-testid='select-masquerading'
      >
        {activeMerchantName ? activeMerchantName : t(`common.merchantSelector.${isUpsider}.masquerade`)}
      </NavItem>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={'lg'}>
        <ModalOverlay />
        <ModalContent borderRadius='24px'>
          <Tabs
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            isFitted
            variant='solid-rounded'
            colorScheme='green'
          >
            <ModalHeader textAlign={'center'} color='neutral.400'>
              {t(`common.merchantSelector.${isUpsider}.modalHeader`)}
              <TabList>
                {isUpsider && (
                  <>
                    <Tab sx={{ flex: 1, justifyContent: 'center' }}>
                      {t(`common.merchantSelector.${isUpsider}.merchant`)}
                    </Tab>
                    <Tab sx={{ flex: 1, justifyContent: 'center' }}>
                      {t(`common.merchantSelector.${isUpsider}.user`)}
                    </Tab>
                  </>
                )}
              </TabList>
            </ModalHeader>
            <ModalBody>
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <TabPanels>
                  <TabPanel>
                    {/* Content for Tab 1 Masquerade by merchant (merchantUuid) */}
                    <InputGroup mb='5'>
                      <InputLeftElement pointerEvents='none'>
                        <SearchIcon color='neutral.400' />
                      </InputLeftElement>
                      <Input
                        borderRadius='full'
                        type='text'
                        id='merchant-search'
                        name='merchant-search'
                        placeholder={t(`common.merchantSelector.${isUpsider}.placeholder_name`)}
                        _placeholder={{
                          color: 'neutral.400',
                        }}
                        value={searchTerm}
                        onChange={handleSearch}
                        data-testid='merchant-search'
                      />
                    </InputGroup>
                    <Flex fontSize='sm' align='center' justify='space-between'>
                      <Text color='neutral.400'>
                        {!searchTerm
                          ? (t(`common.merchantSelector.${isUpsider}.allMerchants`) as string)
                          : (t('common.merchantSelector.searchText', {
                              count: filteredMerchants.count,
                              searchTerm,
                            }) as string)}
                      </Text>
                    </Flex>
                    <RadioGroup value={masqueradeSelection} onChange={handleMasqueradeSelect}>
                      {!!filteredMerchants.list.length && (
                        <FixedSizeList
                          ref={listRef}
                          height={244}
                          width={'100%'}
                          itemCount={filteredMerchants.count}
                          itemSize={40}
                        >
                          {({ index, style }: { index: number; style: CSSProperties }) => {
                            const merchant = filteredMerchants.list[index];
                            return (
                              <MerchantRadio
                                key={merchant.uuid}
                                style={style}
                                value={merchant.uuid ? merchant.uuid : ''}
                                highlight={searchTerm}
                                label={`${merchant.name} ${isUpsider ? ' - ' + merchant.vertical : ''}`}
                              />
                            );
                          }}
                        </FixedSizeList>
                      )}
                    </RadioGroup>
                    {!filteredMerchants.list.length && !!searchTerm && (
                      <Flex gap={2} align='flex-start' justify='center'>
                        <Icon as={FiInfo} />
                        <Text color='neutral.900' fontSize='sm'>
                          {t('common.locationsSelector.noResults', { searchTerm }) as string}
                        </Text>
                      </Flex>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {/* Content for Tab 2 Masquerade by user email (userUuid)*/}
                    <InputGroup mb='5'>
                      <InputLeftElement pointerEvents='none'>
                        <SearchIcon color='neutral.400' />
                      </InputLeftElement>
                      <Input
                        borderRadius='full'
                        type='text'
                        id='merchant-email-search'
                        name='merchant-email-search'
                        placeholder={t(`common.merchantSelector.${isUpsider}.placeholder_email`)}
                        _placeholder={{
                          color: 'neutral.400',
                        }}
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </InputGroup>
                    <Flex fontSize='sm' align='center' justify='space-between'>
                      <Text color='neutral.400'>
                        {!searchTerm
                          ? (t(`common.merchantSelector.${isUpsider}.allMerchantsEmails`) as string)
                          : (t('common.merchantSelector.searchText', {
                              count: filteredUsersEmails.count,
                              searchTerm,
                            }) as string)}
                      </Text>
                    </Flex>
                    <RadioGroup value={masqueradeSelection} onChange={handleMasqueradeSelect}>
                      {!!filteredUsersEmails.list.length && (
                        <FixedSizeList
                          ref={listRef}
                          height={244}
                          width={'100%'}
                          itemCount={filteredUsersEmails.count}
                          itemSize={40}
                        >
                          {({ index, style }: { index: number; style: CSSProperties }) => {
                            const user = filteredUsersEmails.list[index];
                            return (
                              <MerchantRadio
                                key={user.uuid}
                                style={style}
                                value={user.uuid ? user.uuid : ''}
                                highlight={searchTerm}
                                label={`${user.name} ${isUpsider ? user.email + ' - ' + user.vertical : ''}`}
                              />
                            );
                          }}
                        </FixedSizeList>
                      )}
                    </RadioGroup>
                    {!filteredUsersEmails.list.length && !!searchTerm && (
                      <Flex gap={2} align='flex-start' justify='center'>
                        <Icon as={FiInfo} />
                        <Text color='neutral.900' fontSize='sm'>
                          {t('common.locationsSelector.noResults', { searchTerm }) as string}
                        </Text>
                      </Flex>
                    )}
                  </TabPanel>
                </TabPanels>
              )}
            </ModalBody>
          </Tabs>
          <ModalFooter>
            <Button
              id='search-masquerade'
              size='lg'
              variant='brandPrimary'
              display={masqueradeSelection ? undefined : 'none'}
              w='full'
              onClick={handleMasqueradeSelection}
            >
              {t(`common.merchantSelector.${isUpsider}.${tabIndex}.modalFooter`)}
            </Button>
            <Button id='cancel-masquerade' size='lg' variant='accentSecondary' w='full' onClick={onClose}>
              {t(`common.merchantSelector.close`)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
