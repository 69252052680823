import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Link, Text, Wrap } from '@chakra-ui/react';
import { Card } from '@components/card';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Section } from '@/common/components/section';
import { Status } from '@/common/interfaces';

import { selectAuthRequestPath } from '../auth/userSlice';
import {
  selectUserRegistration,
  selectUserRegistrationStatus,
  userRegistrationFetchRequest,
} from '../settings/profile/slice';

export const Onboarding = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  let userRegistration = useAppSelector(selectUserRegistration);
  if (!userRegistration) {
    // If the onboarding page is opened directly, the userRegistration state will be null
    // This could happend during masquerading, when switching between accounts.
    userRegistration = {
      isOnboarding: false,
      locationsStepCompleted: false,
      paymentsStepCompleted: false,
      skipPaymentsStep: false,
      isDefaultWorkflow: false,
    };
  }

  const locationsStepCompleted = userRegistration?.locationsStepCompleted;
  const paymentsStepCompleted = userRegistration?.paymentsStepCompleted;
  const skipPaymentsStep = userRegistration?.skipPaymentsStep;
  const isDefaultWorkflow = userRegistration?.isDefaultWorkflow;
  const loadingStatus = useAppSelector(selectUserRegistrationStatus);

  const allStepsCompleted = locationsStepCompleted && paymentsStepCompleted;

  // Skip payments step - payments step is completed but no location is launched
  const skipPayments = skipPaymentsStep && locationsStepCompleted;

  // Edge case where streamlined workflow and skip payments is selected but no location is launched
  const allStepsSkipped = !isDefaultWorkflow && skipPayments;
  const isPendingLaunch = allStepsCompleted || !isDefaultWorkflow || skipPaymentsStep || allStepsSkipped;

  useEffect(() => {
    dispatch(userRegistrationFetchRequest({ authRequestPath }));
  }, [authRequestPath]);

  useEffect(() => {
    if (!userRegistration.isOnboarding) {
      navigate('/home');
    }
  }, [userRegistration]);

  if (userRegistration.isOnboarding === false) {
    return null;
  }

  return (
    <Box padding={0} margin={0} maxW='100%'>
      {loadingStatus === Status.Loading && (
        <Flex alignItems='center' justifyContent='center' mt={10}>
          <LoadingSpinner />
        </Flex>
      )}
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus === Status.Idle && (
        <>
          {isPendingLaunch && (
            <Box padding={0} margin={0} maxW='100%'>
              <>
                <Wrap direction='column' gap={2} padding={2}>
                  <Card bg='green.200'>
                    <Flex gap={4} justifyContent='center' alignItems='center' height='100%'>
                      <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
                      {isDefaultWorkflow && !skipPaymentsStep && locationsStepCompleted && paymentsStepCompleted && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'} verticalAlign={'self'}>
                          {t('onboardingPage.complete.titleDefault')}
                        </Text>
                      )}
                      {isDefaultWorkflow && skipPaymentsStep && locationsStepCompleted && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'} verticalAlign={'self'}>
                          {t('onboardingPage.complete.titleSkipPayments')}
                        </Text>
                      )}
                      {!isDefaultWorkflow && !skipPaymentsStep && paymentsStepCompleted && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'}>
                          {t('onboardingPage.complete.titleStreamlined')}
                        </Text>
                      )}
                      {!isDefaultWorkflow && skipPaymentsStep && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'}>
                          {t('onboardingPage.complete.titleSkipAll')}
                        </Text>
                      )}
                    </Flex>
                  </Card>
                </Wrap>
              </>
            </Box>
          )}
          {isDefaultWorkflow && (
            <Box padding={0} margin={0} maxW='100%'>
              <>
                <Wrap direction='column' gap={2} padding={2}>
                  <Wrap direction='column'>
                    <Section
                      name={
                        !locationsStepCompleted
                          ? 'onboardingPage.locations.title'
                          : 'onboardingPage.locations.titleCompleted'
                      }
                    >
                      <Flex justifyContent='center' gap={4}>
                        <Card
                          button={{
                            text: locationsStepCompleted ? t('buttons.edit') : t('buttons.start'),
                            to: '/settings/locations',
                            variant: 'brandPrimary',
                          }}
                        >
                          <Flex justifyContent='space-between' gap={4}>
                            <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                              {t('onboardingPage.locations.text')}
                            </Text>
                            {locationsStepCompleted && (
                              <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
                            )}
                          </Flex>
                        </Card>
                      </Flex>
                    </Section>
                  </Wrap>
                </Wrap>
              </>
            </Box>
          )}
          {!skipPaymentsStep && (
            <Box padding={0} margin={0} maxW='100%'>
              <>
                <Wrap direction='column' gap={2} padding={2}>
                  <Wrap direction='column'>
                    <Section
                      name={
                        !paymentsStepCompleted
                          ? 'onboardingPage.payments.title'
                          : 'onboardingPage.payments.titleCompleted'
                      }
                    >
                      <Flex justifyContent='center' gap={4}>
                        <Card
                          button={{
                            text: paymentsStepCompleted ? t('buttons.edit') : t('buttons.start'),
                            to: '/settings/payments',
                            variant: 'brandPrimary',
                          }}
                        >
                          <Flex justifyContent='space-between' gap={4}>
                            <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                              {t('onboardingPage.payments.text')}
                            </Text>
                            {paymentsStepCompleted && (
                              <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
                            )}
                          </Flex>
                        </Card>
                      </Flex>
                    </Section>
                  </Wrap>
                </Wrap>
              </>
            </Box>
          )}
          <Box padding={0} margin={0} maxW='100%'>
            <Trans
              i18nKey={'onboardingPage.businessHelp'}
              components={{ eLink: <Link href='mailto:businesshelp@upside.com' color={'blue.400'} /> }}
            >
              {t('onboardingPage.businessHelp')}
            </Trans>
          </Box>
        </>
      )}
    </Box>
  );
};
